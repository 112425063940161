.hamburger {
  display: block;
  cursor: pointer;
  background-color: transparent;
  overflow: visible;
  position: relative;
  width: 28px;
  height: 19px;
}

.hamburger > div, .hamburger > view {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger > div, .hamburger > div::before, .hamburger > div::after, .hamburger > view, .hamburger > view::before, .hamburger > view::after {
  width: 28px;
  height: 3px;
  background-color: #008E89;
  border-radius: 0;
  position: absolute;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger > div::before, .hamburger > div::after, .hamburger > view::before, .hamburger > view::after {
  content: "";
  display: block;
}

.hamburger > div::before, .hamburger > view::before {
  top: -8px;
}

.hamburger > div::after, .hamburger > view::after {
  bottom: -8px;
}

.hamburger.hamburger-1 > div, .hamburger.hamburger-1 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-1 > div::after, .hamburger.hamburger-1 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1 > div::before, .hamburger.hamburger-1 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div, .hamburger.hamburger-1.active view {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-1.active > div::after, .hamburger.hamburger-1.active view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div::before, .hamburger.hamburger-1.active view::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-2 > div, .hamburger.hamburger-2 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-2 > div::after, .hamburger.hamburger-2 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2 > div::before, .hamburger.hamburger-2 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div, .hamburger.hamburger-2.active > view {
  transform: translate3d(0, -8px, 0) rotate(45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-2.active > div::after, .hamburger.hamburger-2.active > view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div::before, .hamburger.hamburger-2.active > view::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-3 > div, .hamburger.hamburger-3 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-3 > div::before, .hamburger.hamburger-3 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-3 > div::after, .hamburger.hamburger-3 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-3.active > div, .hamburger.hamburger-3.active > view {
  transform: translate3d(0, 8px, 0) rotate(135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-3.active > div::before, .hamburger.hamburger-3.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-3.active > div:after, .hamburger.hamburger-3.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(-270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4 > div, .hamburger.hamburger-4 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-4 > div::before, .hamburger.hamburger-4 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-4 > div::after, .hamburger.hamburger-4 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-4.active > div, .hamburger.hamburger-4.active > view {
  transform: translate3d(0, 8px, 0) rotate(-135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4.active > div::before, .hamburger.hamburger-4.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-4.active > div:after, .hamburger.hamburger-4.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-5 > div, .hamburger.hamburger-5 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::before, .hamburger.hamburger-5 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::after, .hamburger.hamburger-5 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div, .hamburger.hamburger-5.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-5.active > div::before, .hamburger.hamburger-5.active > view::before {
  left: -56px;
  top: -56px;
  transform: translate3d(56px, 56px, 0) rotate(45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div::after, .hamburger.hamburger-5.active > view::after {
  right: -56px;
  top: -56px;
  transform: translate3d(-56px, 56px, 0) rotate(-45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6 > div, .hamburger.hamburger-6 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::before, .hamburger.hamburger-6 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::after, .hamburger.hamburger-6 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div, .hamburger.hamburger-6.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-6.active > div::before, .hamburger.hamburger-6.active > view::before {
  left: -56px;
  top: 56px;
  transform: translate3d(56px, -56px, 0) rotate(-45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div::after, .hamburger.hamburger-6.active > view::after {
  right: -56px;
  top: 56px;
  transform: translate3d(-56px, -56px, 0) rotate(45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-7 > div, .hamburger.hamburger-7 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-7 > div::before, .hamburger.hamburger-7 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-7 > div::after, .hamburger.hamburger-7 > view::after {
  top: 16px;
}

.hamburger.hamburger-7.active > div, .hamburger.hamburger-7.active > view {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger.hamburger-7.active > div::before, .hamburger.hamburger-7.active > view::before {
  transform: rotate(-45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-7.active > div::after, .hamburger.hamburger-7.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

.hamburger.hamburger-8 > div, .hamburger.hamburger-8 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-8 > div::before, .hamburger.hamburger-8 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-8 > div::after, .hamburger.hamburger-8 > view::after {
  top: 16px;
}

.hamburger.hamburger-8.active > div, .hamburger.hamburger-8.active > view {
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

.hamburger.hamburger-8.active > div::before, .hamburger.hamburger-8.active > view::before {
  transform: rotate(45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-8.active > div::after, .hamburger.hamburger-8.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(90deg);
}

.hamburger.hamburger-9 > div, .hamburger.hamburger-9 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-9 > div::before, .hamburger.hamburger-9 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-9 > div::after, .hamburger.hamburger-9 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div, .hamburger.hamburger-9.active > view {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-9.active > div::before, .hamburger.hamburger-9.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div::after, .hamburger.hamburger-9.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-10 > div, .hamburger.hamburger-10 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-10 > div::before, .hamburger.hamburger-10 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-10 > div::after, .hamburger.hamburger-10 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div, .hamburger.hamburger-10.active > view {
  transform: rotate(-225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-10.active > div::before, .hamburger.hamburger-10.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div::after, .hamburger.hamburger-10.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-11 > div, .hamburger.hamburger-11 > view {
  margin-top: -2px;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-11 > div::before, .hamburger.hamburger-11 > view::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11 > div::after, .hamburger.hamburger-11 > view::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div, .hamburger.hamburger-11.active > view {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-11.active > div::before, .hamburger.hamburger-11.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease, opacity 0.1s 0.14s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div::after, .hamburger.hamburger-11.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-12 > div, .hamburger.hamburger-12 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::before, .hamburger.hamburger-12 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > view::before {
  transition-property: top, opacity, background-color;
}

.hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-12.active > div, .hamburger.hamburger-12.active > view {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::before, .hamburger.hamburger-12.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::after {
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger.hamburger-13 > div, .hamburger.hamburger-13 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::before, .hamburger.hamburger-13 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > view::before {
  transition-property: top, opacity;
}

.hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-13.active > div, .hamburger.hamburger-13.active > view {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::before, .hamburger.hamburger-13.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  left: 0;
  top: 0;
}

@media (max-width: 767px) {
  .page {
    height: auto;
  }
}

.banner {
  margin-top: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .banner {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .banner {
    height: 240px;
  }
}

.banner #banner-swiper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.banner #banner-swiper .swiper-slide {
  overflow: hidden;
}

.banner #banner-swiper .swiper-slide a {
  height: 100%;
  position: relative;
  display: block;
  width: 100%;
}

.banner #banner-swiper .swiper-slide a img {
  max-width: 100%;
  height: 100%;
  object-fit: fill;
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-slide a img {
    object-fit: cover;
  }
}

.banner #banner-swiper .swiper-pagination {
  bottom: 0.53rem;
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-pagination {
    bottom: 10px;
  }
}

.banner #banner-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: #b5b5b5;
  opacity: 1;
  margin: 0;
  margin-right: 20px;
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-pagination .swiper-pagination-bullet {
    margin-right: 18px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-pagination .swiper-pagination-bullet {
    margin-right: 16px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-pagination .swiper-pagination-bullet {
    margin-right: 14px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-pagination .swiper-pagination-bullet {
    margin-right: 12px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-pagination .swiper-pagination-bullet {
    margin-right: 10px;
  }
}

.banner #banner-swiper .swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.banner #banner-swiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffffff;
}

.index .section {
  height: 100%;
}

@media (max-width: 767px) {
  .index .section {
    height: auto;
  }
}

.index .page1_bg {
  height: 100%;
}

@media (max-width: 767px) {
  .index .page1_bg {
    height: auto;
  }
}

.index .page2_bg {
  height: 100%;
}

@media (max-width: 767px) {
  .index .page2_bg {
    height: auto;
  }
}

.index .page3_bg {
  height: 100%;
}

@media (max-width: 767px) {
  .index .page3_bg {
    height: auto;
  }
}

.index .page4_bg {
  height: 100%;
}

@media (max-width: 767px) {
  .index .page4_bg {
    height: auto;
  }
}

.index .page5_bg {
  height: 100%;
}

@media (max-width: 767px) {
  .index .page5_bg {
    height: auto;
  }
}

.index .page6_bg {
  height: 100%;
}

@media (max-width: 767px) {
  .index .page6_bg {
    height: auto;
  }
}

.index .section1 {
  margin-bottom: 0;
  position: relative;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .index .section1 {
    margin-bottom: 30px;
  }
}

.index .section1 .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-top: 1.5rem;
}

@media (max-width: 767px) {
  .index .section1 .container {
    flex-wrap: wrap;
  }
}

@media (max-width: 1366px) {
  .index .section1 .container {
    padding-top: 2rem;
  }
}

@media (max-width: 767px) {
  .index .section1 .container {
    padding-top: 30px;
  }
}

.index .section1 .container .left {
  position: relative;
  z-index: 1;
  width: 561px;
  font-size: 14px;
  color: #202020;
}

@media (max-width: 1600px) {
  .index .section1 .container .left {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .index .section1 .container .left {
    width: 100%;
  }
}

.index .section1 .container .left .text {
  margin-top: 80px;
  width: 520px;
  line-height: 30px;
  text-align: justify;
}

@media (max-width: 1600px) {
  .index .section1 .container .left .text {
    margin-top: 68px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .container .left .text {
    margin-top: 56px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .container .left .text {
    margin-top: 44px;
  }
}

@media (max-width: 991px) {
  .index .section1 .container .left .text {
    margin-top: 32px;
  }
}

@media (max-width: 767px) {
  .index .section1 .container .left .text {
    margin-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index .section1 .container .left .text {
    width: 100%;
  }
}

.index .section1 .container .left a {
  display: block;
  width: fit-content;
  margin-top: 35px;
}

@media (max-width: 1600px) {
  .index .section1 .container .left a {
    margin-top: 32px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .container .left a {
    margin-top: 29px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .container .left a {
    margin-top: 26px;
  }
}

@media (max-width: 991px) {
  .index .section1 .container .left a {
    margin-top: 23px;
  }
}

@media (max-width: 767px) {
  .index .section1 .container .left a {
    margin-top: 20px;
  }
}

.index .section1 .container .left a img {
  margin-left: 0.12rem;
}

.index .section1 .container .left a:hover .scroll-cont div {
  transform: translateY(-100%);
  color: #000;
}

.index .section1 .container .left a:hover .scroll-cont div img {
  opacity: 1;
}

.index .section1 .container .left a .scroll-cont {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 17px;
}

.index .section1 .container .left a .scroll-cont div {
  display: flex;
  align-items: center;
  transition: all .25s;
}

.index .section1 .container .left a .scroll-cont div img {
  opacity: .6;
}

.index .section1 .container .right {
  width: 750px;
  margin-top: 0;
}

@media (max-width: 1600px) {
  .index .section1 .container .right {
    width: 55%;
  }
}

@media (max-width: 767px) {
  .index .section1 .container .right {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index .section1 .container .right {
    margin-top: 30px;
  }
}

.index .section1 .container .right ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex-wrap: nowrap;
}

@media (max-width: 767px) {
  .index .section1 .container .right ul {
    flex-wrap: wrap;
  }
}

.index .section1 .container .right ul li {
  width: fit-content;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .index .section1 .container .right ul li {
    width: calc(100% / 3);
  }
}

@media (max-width: 767px) {
  .index .section1 .container .right ul li {
    margin-bottom: 15px;
  }
}

.index .section1 .container .right ul li img {
  margin: auto;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .index .section1 .container .right ul li img {
    margin-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .container .right ul li img {
    margin-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .container .right ul li img {
    margin-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .index .section1 .container .right ul li img {
    margin-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .index .section1 .container .right ul li img {
    margin-bottom: 10px;
  }
}

.index .section1 .container .right ul li .mun_top {
  font-family: OPPOSans-M;
  color: #a50303;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.index .section1 .container .right ul li .mun_top h2 {
  font-size: 46px;
  letter-spacing: -2px;
}

@media (max-width: 1600px) {
  .index .section1 .container .right ul li .mun_top h2 {
    font-size: 42.8px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .container .right ul li .mun_top h2 {
    font-size: 39.6px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .container .right ul li .mun_top h2 {
    font-size: 36.4px;
  }
}

@media (max-width: 991px) {
  .index .section1 .container .right ul li .mun_top h2 {
    font-size: 33.2px;
  }
}

@media (max-width: 767px) {
  .index .section1 .container .right ul li .mun_top h2 {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .index .section1 .container .right ul li .mun_top h2 {
    letter-spacing: -1.8px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .container .right ul li .mun_top h2 {
    letter-spacing: -1.6px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .container .right ul li .mun_top h2 {
    letter-spacing: -1.4px;
  }
}

@media (max-width: 991px) {
  .index .section1 .container .right ul li .mun_top h2 {
    letter-spacing: -1.2px;
  }
}

@media (max-width: 767px) {
  .index .section1 .container .right ul li .mun_top h2 {
    letter-spacing: -1px;
  }
}

.index .section1 .container .right ul li .mun_top span {
  font-size: 14px;
  letter-spacing: -1px;
}

.index .section1 .container .right .video {
  margin-top: 35px;
  height: 360px;
  width: 100%;
  position: relative;
}

@media (max-width: 767px) {
  .index .section1 .container .right .video {
    height: 240px;
  }
}

.index .section1 .container .right .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.index .section1 .container .right .video .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.index .section1 .container .right .video .play img {
  margin: auto;
}

.index .section1 .container .right .tips {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.index .section1 .container .right .tips span {
  display: block;
  width: 50px;
  height: 1px;
  background-color: #d2d2d2;
  margin-left: 12px;
}

.index .section1 .bg1 {
  display: block;
  position: absolute;
  left: 7px;
  bottom: 37px;
  z-index: 0;
}

@media (max-width: 1024px) {
  .index .section1 .bg1 {
    display: none;
  }
}

.index .section1 .bg2 {
  display: block;
  position: absolute;
  left: 0;
  bottom: 42px;
  z-index: 0;
}

@media (max-width: 1024px) {
  .index .section1 .bg2 {
    display: none;
  }
}

.index .section1 .group-nav {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
}

@media (max-width: 1024px) {
  .index .section1 .group-nav {
    display: none;
  }
}

.index .section1 .group-nav li {
  font-size: 18px;
  letter-spacing: 1px;
  color: #202020;
  position: absolute;
}

@media (max-width: 1600px) {
  .index .section1 .group-nav li {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .group-nav li {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .group-nav li {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .section1 .group-nav li {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index .section1 .group-nav li {
    font-size: 16px;
  }
}

.index .section1 .group-nav li:nth-child(1) {
  bottom: 90px;
  left: 217px;
}

.index .section1 .group-nav li:nth-child(2) {
  bottom: 52px;
  left: 424px;
}

.index .section1 .group-nav li:nth-child(3) {
  bottom: 125px;
  left: 615px;
}

.index .section1 .group-nav li:nth-child(4) {
  bottom: 164px;
  left: 832px;
}

.index .section1 .group-nav li a {
  transition: all .3s;
}

.index .section1 .group-nav li a span {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #c9a063;
  border-radius: 50%;
  margin: auto;
  margin-top: 20px;
  border: 4px solid transparent;
  transition: all .3s;
}

.index .section1 .group-nav li:hover a {
  color: #c9a063;
}

.index .section1 .group-nav li:hover a span {
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: 2px solid #c9a063;
}

.index .section2 {
  position: relative;
  height: 100%;
}

.index .section2 .container {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 164px;
  z-index: 10;
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .index .section2 .container {
    position: relative;
  }
}

@media (max-width: 767px) {
  .index .section2 .container {
    top: 0;
  }
}

@media (max-width: 767px) {
  .index .section2 .container {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .index .section2 .container {
    margin-bottom: 30px;
  }
}

.index .section2 .container .title h2 {
  color: #fff;
  opacity: 0.5;
}

@media (max-width: 767px) {
  .index .section2 .container .title h2 {
    color: #c9a063;
  }
}

@media (max-width: 767px) {
  .index .section2 .container .title h2 {
    opacity: 0.15;
  }
}

.index .section2 .container .title h3 {
  color: #fff;
}

@media (max-width: 767px) {
  .index .section2 .container .title h3 {
    color: #3c4242;
  }
}

.index .section2 ul {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
}

@media (max-width: 767px) {
  .index .section2 ul {
    flex-wrap: wrap;
  }
}

.index .section2 ul li {
  position: relative;
  width: calc(100% / 3);
  height: 100%;
  transition: all .5s;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .index .section2 ul li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li {
    margin-bottom: 20px;
  }
}

.index .section2 ul li .bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.index .section2 ul li.active {
  width: 66.5%;
}

@media (max-width: 767px) {
  .index .section2 ul li.active {
    width: 100%;
  }
}

.index .section2 ul li.active .text h2 {
  font-size: 36px;
}

@media (max-width: 1600px) {
  .index .section2 ul li.active .text h2 {
    font-size: 26px;
  }
}

.index .section2 ul li.active .text p {
  display: block;
}

.index .section2 ul li.active .text .more {
  display: flex;
}

.index .section2 ul li.active1 {
  width: 16.75%;
}

@media (max-width: 767px) {
  .index .section2 ul li.active1 {
    width: 100%;
  }
}

.index .section2 ul li .text {
  width: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 400px;
  z-index: 2;
}

.index .section2 ul li .text .pic {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: solid 1px #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .index .section2 ul li .text .pic {
    width: 100px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li .text .pic {
    height: 100px;
  }
}

@media (max-width: 1600px) {
  .index .section2 ul li .text .pic {
    margin-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li .text .pic {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li .text .pic {
    margin-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li .text .pic {
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li .text .pic {
    margin-bottom: 15px;
  }
}

.index .section2 ul li .text h2 {
  font-family: OPPOSans-M;
  font-size: 22px;
  letter-spacing: 1px;
  color: #fff;
  text-align: center;
}

@media (max-width: 767px) {
  .index .section2 ul li .text h2 {
    font-size: 26px;
  }
}

.index .section2 ul li .text p {
  display: none;
  width: 712px;
  font-size: 16px;
  letter-spacing: 1px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 17px;
}

@media (max-width: 767px) {
  .index .section2 ul li .text p {
    display: block;
  }
}

@media (max-width: 1600px) {
  .index .section2 ul li .text p {
    width: 600px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li .text p {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index .section2 ul li .text p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li .text p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li .text p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li .text p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li .text p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index .section2 ul li .text p {
    line-height: 29px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li .text p {
    line-height: 28px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li .text p {
    line-height: 27px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li .text p {
    line-height: 26px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li .text p {
    line-height: 25px;
  }
}

@media (max-width: 1600px) {
  .index .section2 ul li .text p {
    margin-top: 14px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li .text p {
    margin-top: 13px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li .text p {
    margin-top: 12px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li .text p {
    margin-top: 11px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li .text p {
    margin-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index .section2 ul li .text p {
    margin-bottom: 15.86667px;
  }
}

@media (max-width: 1366px) {
  .index .section2 ul li .text p {
    margin-bottom: 14.73333px;
  }
}

@media (max-width: 1024px) {
  .index .section2 ul li .text p {
    margin-bottom: 13.6px;
  }
}

@media (max-width: 991px) {
  .index .section2 ul li .text p {
    margin-bottom: 12.46667px;
  }
}

@media (max-width: 767px) {
  .index .section2 ul li .text p {
    margin-bottom: 11.33333px;
  }
}

.index .section2 ul li .text .more {
  display: none;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: solid 1px #ffffff;
  align-items: center;
  justify-content: center;
  margin: auto;
}

@media (max-width: 767px) {
  .index .section2 ul li .text .more {
    display: flex;
  }
}

.index .section2 ul li .model {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  z-index: 1;
}

.index .section3 {
  width: 100%;
  height: 100%;
  background: url(./images/core-bg.png) no-repeat;
  padding-bottom: 0;
  background-size: cover;
}

@media (max-width: 991px) {
  .index .section3 {
    padding-bottom: 30px;
  }
}

.index .section3 .container {
  position: relative;
  height: 100%;
}

.index .section3 .container .title {
  padding-top: 150px;
}

.index .section3 .container ul {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  height: 500px;
  position: relative;
  display: block;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media (max-width: 1366px) {
  .index .section3 .container ul {
    width: 1000px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul {
    width: 100%;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul {
    margin-left: auto;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul {
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul {
    margin-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul {
    height: 400px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul {
    height: auto;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul {
    display: flex;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul {
    flex-wrap: wrap;
  }
}

.index .section3 .container ul li {
  width: 340px;
  height: 340px;
  background: url(./images/core-yy.png) no-repeat;
  background-size: cover;
  border-radius: 50%;
  position: absolute;
  transition: all .3s;
  margin-bottom: 0;
  background-size: cover;
}

@media (max-width: 1600px) {
  .index .section3 .container ul li {
    width: 240px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li {
    width: 200px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li {
    width: 175px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li {
    height: 240px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li {
    height: 200px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li {
    height: 175px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li {
    position: relative;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li {
    position: static;
  }
}

@media (max-width: 991px) {
  .index .section3 .container ul li {
    margin-bottom: 15px;
  }
}

.index .section3 .container ul li .core-icon {
  width: fit-content;
  margin: auto;
  position: relative;
  z-index: 10;
  padding-top: 86px;
}

@media (max-width: 1600px) {
  .index .section3 .container ul li .core-icon {
    padding-top: 0.86rem;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li .core-icon {
    padding-top: 30px;
  }
}

.index .section3 .container ul li .core-icon .img2 {
  display: none;
}

.index .section3 .container ul li a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.index .section3 .container ul li a .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  transition: all .3s;
  overflow: hidden;
  border-radius: 50%;
}

.index .section3 .container ul li a .bg img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.index .section3 .container ul li a .model {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: -1;
  transition: all .3s;
  overflow: hidden;
}

.index .section3 .container ul li p {
  transition: all .3s;
  position: relative;
  z-index: 10;
  text-align: center;
  margin: auto;
  font-family: OPPOSans-H;
  font-size: 20px;
  color: #3c4242;
  line-height: 24px;
  margin-top: 28px;
}

@media (max-width: 1600px) {
  .index .section3 .container ul li p {
    font-size: 0.2rem;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li p {
    margin-top: 0.28rem;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li p {
    margin-top: 10px;
  }
}

.index .section3 .container ul li:nth-child(1) {
  bottom: 0;
  left: 0;
}

@media (max-width: 1366px) {
  .index .section3 .container ul li:nth-child(1) {
    bottom: -75px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li:nth-child(1) {
    left: 100px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li:nth-child(1) {
    left: 75px;
  }
}

.index .section3 .container ul li:nth-child(2) {
  top: 50px;
  left: 277px;
}

@media (max-width: 1366px) {
  .index .section3 .container ul li:nth-child(2) {
    top: 35px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li:nth-child(2) {
    left: 278px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li:nth-child(2) {
    left: 35px;
  }
}

.index .section3 .container ul li:nth-child(3) {
  top: 0;
  left: 565px;
  right: auto;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1600px) {
  .index .section3 .container ul li:nth-child(3) {
    left: 0;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li:nth-child(3) {
    right: 0;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li:nth-child(3) {
    margin-left: 0;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li:nth-child(3) {
    margin-right: 0;
  }
}

.index .section3 .container ul li:nth-child(4) {
  top: 50px;
  right: 277px;
}

@media (max-width: 1366px) {
  .index .section3 .container ul li:nth-child(4) {
    top: 35px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li:nth-child(4) {
    right: 278px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li:nth-child(4) {
    right: 35px;
  }
}

.index .section3 .container ul li:nth-child(5) {
  bottom: 0;
  right: 0;
}

@media (max-width: 1366px) {
  .index .section3 .container ul li:nth-child(5) {
    bottom: -75px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li:nth-child(5) {
    right: 100px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li:nth-child(5) {
    right: 75px;
  }
}

.index .section3 .container ul li:hover a .core-icon .img1, .index .section3 .container ul li.active a .core-icon .img1 {
  display: none;
}

.index .section3 .container ul li:hover a .core-icon .img2, .index .section3 .container ul li.active a .core-icon .img2 {
  display: block;
}

.index .section3 .container ul li:hover a p, .index .section3 .container ul li.active a p {
  color: #fff;
}

.index .section3 .container ul li:hover a .bg, .index .section3 .container ul li.active a .bg {
  opacity: 1;
  z-index: 0;
}

.index .section3 .container ul li:hover a .model, .index .section3 .container ul li.active a .model {
  opacity: 1;
  z-index: 1;
}

.index .section3 .container .core-text .text {
  display: none;
  width: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 60px;
  top: auto;
  text-align: center;
}

@media (max-width: 1600px) {
  .index .section3 .container .core-text .text {
    position: relative;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container .core-text .text {
    bottom: auto;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container .core-text .text {
    top: -60px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container .core-text .text {
    top: 60px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container .core-text .text {
    top: 0;
  }
}

.index .section3 .container .core-text .text h2 {
  font-family: OPPOSans-H;
  font-size: 24px;
  color: #c9a063;
  margin-bottom: 10px;
}

@media (max-width: 1600px) {
  .index .section3 .container .core-text .text h2 {
    font-size: 23.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container .core-text .text h2 {
    font-size: 22.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container .core-text .text h2 {
    font-size: 21.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container .core-text .text h2 {
    font-size: 20.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container .core-text .text h2 {
    font-size: 20px;
  }
}

.index .section3 .container .core-text .text p {
  font-size: 16px;
  line-height: 30px;
  color: #3c4242;
}

@media (max-width: 1600px) {
  .index .section3 .container .core-text .text p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container .core-text .text p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container .core-text .text p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container .core-text .text p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container .core-text .text p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container .core-text .text p {
    line-height: 29px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container .core-text .text p {
    line-height: 28px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container .core-text .text p {
    line-height: 27px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container .core-text .text p {
    line-height: 26px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container .core-text .text p {
    line-height: 25px;
  }
}

.index .section3 .container .core-text .text.active {
  display: block;
}

.index .section4 {
  margin-top: 145px;
}

@media (max-width: 1600px) {
  .index .section4 {
    margin-top: 122px;
  }
}

@media (max-width: 1366px) {
  .index .section4 {
    margin-top: 99px;
  }
}

@media (max-width: 1024px) {
  .index .section4 {
    margin-top: 76px;
  }
}

@media (max-width: 991px) {
  .index .section4 {
    margin-top: 53px;
  }
}

@media (max-width: 767px) {
  .index .section4 {
    margin-top: 30px;
  }
}

.index .section4 ul {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 55px;
}

@media (max-width: 767px) {
  .index .section4 ul {
    flex-wrap: wrap;
  }
}

@media (max-width: 1600px) {
  .index .section4 ul {
    margin-top: 48px;
  }
}

@media (max-width: 1366px) {
  .index .section4 ul {
    margin-top: 41px;
  }
}

@media (max-width: 1024px) {
  .index .section4 ul {
    margin-top: 34px;
  }
}

@media (max-width: 991px) {
  .index .section4 ul {
    margin-top: 27px;
  }
}

@media (max-width: 767px) {
  .index .section4 ul {
    margin-top: 20px;
  }
}

.index .section4 ul li {
  width: 316px;
  margin-bottom: 0;
}

@media (max-width: 1600px) {
  .index .section4 ul li {
    width: 24%;
  }
}

@media (max-width: 767px) {
  .index .section4 ul li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index .section4 ul li {
    margin-bottom: 20px;
  }
}

.index .section4 ul li .pic {
  overflow: hidden;
}

.index .section4 ul li .pic img {
  transition: all .3s;
  aspect-ratio: 472 / 279;
  max-width: 100%;
}

.index .section4 ul li .text {
  position: relative;
  height: 250px;
  background-color: #f7f7f7;
  color: #3c4242;
  transition: all .3s;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
}

@media (max-width: 1600px) {
  .index .section4 ul li .text {
    height: 200px;
  }
}

@media (max-width: 767px) {
  .index .section4 ul li .text {
    height: auto;
  }
}

@media (max-width: 1600px) {
  .index .section4 ul li .text {
    padding-left: 18px;
  }
}

@media (max-width: 1366px) {
  .index .section4 ul li .text {
    padding-left: 16px;
  }
}

@media (max-width: 1024px) {
  .index .section4 ul li .text {
    padding-left: 14px;
  }
}

@media (max-width: 991px) {
  .index .section4 ul li .text {
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .index .section4 ul li .text {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index .section4 ul li .text {
    padding-right: 18px;
  }
}

@media (max-width: 1366px) {
  .index .section4 ul li .text {
    padding-right: 16px;
  }
}

@media (max-width: 1024px) {
  .index .section4 ul li .text {
    padding-right: 14px;
  }
}

@media (max-width: 991px) {
  .index .section4 ul li .text {
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .index .section4 ul li .text {
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .index .section4 ul li .text {
    padding-bottom: 20px;
  }
}

.index .section4 ul li .text h2 {
  font-family: OPPOSans-H;
  font-size: 16px;
  line-height: 30px;
  padding-top: 17px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@media (max-width: 1600px) {
  .index .section4 ul li .text h2 {
    padding-top: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section4 ul li .text h2 {
    padding-top: 14.2px;
  }
}

@media (max-width: 1024px) {
  .index .section4 ul li .text h2 {
    padding-top: 12.8px;
  }
}

@media (max-width: 991px) {
  .index .section4 ul li .text h2 {
    padding-top: 11.4px;
  }
}

@media (max-width: 767px) {
  .index .section4 ul li .text h2 {
    padding-top: 10px;
  }
}

.index .section4 ul li .text p {
  font-size: 14px;
  line-height: 24px;
  margin-top: 15px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

@media (max-width: 1600px) {
  .index .section4 ul li .text p {
    -webkit-line-clamp: 2;
  }
}

.index .section4 ul li .time {
  font-size: 18px;
  position: absolute;
  bottom: 23px;
}

@media (max-width: 1600px) {
  .index .section4 ul li .time {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index .section4 ul li .time {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index .section4 ul li .time {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .section4 ul li .time {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index .section4 ul li .time {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .index .section4 ul li .time {
    position: static;
  }
}

@media (max-width: 767px) {
  .index .section4 ul li .time {
    bottom: 0;
  }
}

.index .section4 ul li:hover .pic img {
  transform: scale(1.1);
}

.index .section4 ul li:hover .text {
  color: #fff;
  background-color: #c9a063;
}

.index .section5 {
  position: relative;
  width: 100%;
  height: 100%;
}

.index .section5 .bg {
  display: flex;
  height: 100%;
}

@media (max-width: 767px) {
  .index .section5 .bg {
    display: block;
  }
}

.index .section5 .bg div {
  width: 50%;
}

@media (max-width: 767px) {
  .index .section5 .bg div {
    width: 100%;
  }
}

.index .section5 .bg .left {
  height: auto;
}

@media (max-width: 767px) {
  .index .section5 .bg .left {
    height: 400px;
  }
}

.index .section5 .bg .right {
  height: auto;
}

@media (max-width: 767px) {
  .index .section5 .bg .right {
    height: 450px;
  }
}

.index .section5 .bg img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.index .section5 .container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  z-index: 10;
}

@media (max-width: 767px) {
  .index .section5 .container {
    flex-wrap: wrap;
  }
}

.index .section5 .container .left {
  width: 500px;
  height: auto;
  box-sizing: border-box;
  padding-top: 0;
}

@media (max-width: 1366px) {
  .index .section5 .container .left {
    width: 45%;
  }
}

@media (max-width: 767px) {
  .index .section5 .container .left {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index .section5 .container .left {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .index .section5 .container .left {
    padding-top: 20px;
  }
}

.index .section5 .container .left p {
  font-size: 16px;
  line-height: 30px;
  color: #3c4242;
  margin-top: 30px;
}

@media (max-width: 1600px) {
  .index .section5 .container .left p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section5 .container .left p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .section5 .container .left p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .section5 .container .left p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .section5 .container .left p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index .section5 .container .left p {
    line-height: 29px;
  }
}

@media (max-width: 1366px) {
  .index .section5 .container .left p {
    line-height: 28px;
  }
}

@media (max-width: 1024px) {
  .index .section5 .container .left p {
    line-height: 27px;
  }
}

@media (max-width: 991px) {
  .index .section5 .container .left p {
    line-height: 26px;
  }
}

@media (max-width: 767px) {
  .index .section5 .container .left p {
    line-height: 25px;
  }
}

.index .section5 .container .left a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  width: 143px;
  height: 49px;
  background-color: #c9a063;
  box-sizing: border-box;
  margin-top: 35px;
  font-size: 14px;
  color: #ffffff;
}

@media (max-width: 1600px) {
  .index .section5 .container .left a {
    margin-top: 32px;
  }
}

@media (max-width: 1366px) {
  .index .section5 .container .left a {
    margin-top: 29px;
  }
}

@media (max-width: 1024px) {
  .index .section5 .container .left a {
    margin-top: 26px;
  }
}

@media (max-width: 991px) {
  .index .section5 .container .left a {
    margin-top: 23px;
  }
}

@media (max-width: 767px) {
  .index .section5 .container .left a {
    margin-top: 20px;
  }
}

.index .section5 .container .left a img {
  margin-left: 0.15rem;
}

.index .section5 .container .left a:hover .scroll-cont div {
  transform: translateY(-100%);
  color: #fff;
}

.index .section5 .container .left a:hover .scroll-cont div img {
  opacity: 1;
}

.index .section5 .container .left a .scroll-cont {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 17px;
}

.index .section5 .container .left a .scroll-cont div {
  display: flex;
  align-items: center;
  transition: all .25s;
}

.index .section5 .container .left a .scroll-cont div img {
  opacity: .6;
}

.index .section5 .container .right {
  width: 508px;
  margin-top: 0;
  height: auto;
}

@media (max-width: 1366px) {
  .index .section5 .container .right {
    width: 45%;
  }
}

@media (max-width: 767px) {
  .index .section5 .container .right {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index .section5 .container .right {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .index .section5 .container .right {
    height: 450px;
  }
}

.index .section5 .container .right .pic {
  width: fit-content;
  margin: auto;
}

.index .section5 .container .right h2 {
  font-family: OPPOSans-H;
  font-size: 36px;
  letter-spacing: -1px;
  color: #3c4242;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 1600px) {
  .index .section5 .container .right h2 {
    font-size: 0.36rem;
  }
}

@media (max-width: 767px) {
  .index .section5 .container .right h2 {
    font-size: 24px;
  }
}

.index .section5 .container .right ul {
  width: 100%;
  margin-top: 10px;
}

.index .section5 .container .right ul li {
  font-size: 14px;
}

.index .section5 .container .right ul li div {
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(255, 255, 255, 0.6);
  margin-bottom: 10px;
  color: #3c4242;
  line-height: 30px;
}

@media (max-width: 1600px) {
  .index .section5 .container .right ul li div {
    padding-left: 18px;
  }
}

@media (max-width: 1366px) {
  .index .section5 .container .right ul li div {
    padding-left: 16px;
  }
}

@media (max-width: 1024px) {
  .index .section5 .container .right ul li div {
    padding-left: 14px;
  }
}

@media (max-width: 991px) {
  .index .section5 .container .right ul li div {
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .index .section5 .container .right ul li div {
    padding-left: 10px;
  }
}

.index .section5 .container .right ul li div input {
  border: none;
  outline: none;
  background: transparent;
  flex: 1;
  display: block;
  width: 100%;
}

.index .section5 .container .right ul li:first-child {
  display: flex;
}

.index .section5 .container .right ul li:first-child div {
  width: 250px;
  height: 50px;
}

.index .section5 .container .right ul li:first-child div:first-child {
  margin-right: 8px;
}

.index .section5 .container .right ul li:nth-child(3) {
  height: 100px;
}

.index .section5 .container .right ul li:nth-child(3) div {
  height: 100%;
  padding-top: 20px;
  align-items: flex-start;
}

@media (max-width: 1600px) {
  .index .section5 .container .right ul li:nth-child(3) div {
    padding-top: 18px;
  }
}

@media (max-width: 1366px) {
  .index .section5 .container .right ul li:nth-child(3) div {
    padding-top: 16px;
  }
}

@media (max-width: 1024px) {
  .index .section5 .container .right ul li:nth-child(3) div {
    padding-top: 14px;
  }
}

@media (max-width: 991px) {
  .index .section5 .container .right ul li:nth-child(3) div {
    padding-top: 12px;
  }
}

@media (max-width: 767px) {
  .index .section5 .container .right ul li:nth-child(3) div {
    padding-top: 10px;
  }
}

.index .section5 .container .right ul li:nth-child(3) textarea {
  resize: none;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  height: 100%;
  flex: 1;
}

.index .section5 .container .right ul li:nth-child(4) {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.index .section5 .container .right ul li:nth-child(4) h3 {
  width: 100%;
  margin-bottom: 10px;
}

.index .section5 .container .right ul li:nth-child(4) div {
  padding: 0;
  margin-bottom: 0;
  background: transparent;
}

.index .section5 .container .right ul li:nth-child(4) .verify-code {
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  width: 200px !important;
  height: 40px !important;
  box-sizing: border-box;
  margin-bottom: 0;
  box-sizing: border-box;
  margin-left: 10px;
}

.index .section5 .container .right ul li:nth-child(4) .cerify-code-panel {
  height: 100%;
  overflow: hidden;
  margin-bottom: 0;
}

.index .section5 .container .right ul li:nth-child(4) .verify-input-area {
  width: 60%;
  padding-right: 10px;
}

.index .section5 .container .right ul li:nth-child(4) .verify-change-area {
  line-height: 30px;
}

.index .section5 .container .right ul li:nth-child(4) .varify-input-code {
  display: inline-block;
  width: 100px;
  height: 40px;
  border: 1px solid;
  margin-left: 20px;
}

.index .section5 .container .right ul li:nth-child(4) .verify-change-code {
  color: #337AB7;
  cursor: pointer;
}

.index .section5 .container .right ul li:nth-child(4) .verify-btn {
  width: 200px;
  height: 30px;
  background-color: #337AB7;
  color: #FFFFFF;
  border: none;
  margin-top: 10px;
}

.index .section5 .container .right ul li:last-child {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.index .section5 .container .right ul li:last-child input[type="submit"] {
  border: none;
  width: 160px;
  height: 50px;
  background-color: #c9a063;
  color: #fff;
  letter-spacing: 3px;
  cursor: pointer;
}

.index footer {
  height: 300px;
  padding-bottom: 0;
  padding-top: 30px;
}

@media screen and (max-height: 840px) {
  .index .section4 ul li .pic img {
    aspect-ratio: 3 / 2;
  }
}

@media screen and (max-height: 800px) {
  .index .section4 {
    margin-top: 100px;
  }
  .index .section3 .container .title {
    padding-top: 100px;
  }
  .index .section2 .container {
    top: 100px;
  }
  .index .section3 .container .core-text .text {
    position: relative;
    bottom: auto;
    top: -140px;
  }
  .index .section4 ul li .text p {
    -webkit-line-clamp: 2;
  }
  .index .section4 ul li .text {
    height: 200px;
  }
}

@media screen and (max-height: 770px) {
  .index .section1 .bg2 {
    display: none;
  }
  .index .section1 .group-nav {
    display: none;
  }
}

@media screen and (max-height: 750px) {
  .index .section3 .container ul {
    top: -50px;
  }
  .index .section3 .container .core-text .text {
    top: -160px;
  }
  .index .section4 ul {
    margin-top: 20px;
  }
  .index footer {
    height: 360px;
  }
  .index .section2 ul li .text {
    top: 230px;
  }
}

@media (max-width: 1600px) and (max-height: 800px) {
  .index .section3 .container ul {
    top: -50px;
  }
  .index .section3 .container .core-text .text {
    top: -80px;
  }
}

@media (max-width: 1366px) and (max-height: 800px) {
  .index .section3 .container ul {
    top: 0px;
  }
  .index .section3 .container .core-text .text {
    top: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .index .section3 .container .core-text .text {
    top: 0;
    margin-top: 30px;
  }
}

@media screen and (max-width: 1024px) and (max-height: 800px) {
  .index .section4 {
    margin-top: 30px;
  }
  .index .section3 .container .title {
    padding-top: 30px;
  }
  .index footer {
    height: auto;
  }
  .index .section2 .container {
    top: 0;
  }
  .index .section2 ul li .text {
    top: 30px;
  }
}
