@import "../../assets/styles/utils";
.page{
    width:100%;
    @include res(height,100%,(xs:auto));
    overflow:hidden;
    position: relative;
    left:0;
    top:0;
}
.banner {
    @include res(margin-top,0,(md:60px));
    width: 100%;
    @include res(height,100%,(xs:240px));
    #banner-swiper {
        width: 100%;
        height: 100%;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        .swiper-slide {
            overflow: hidden;
            a {
                height: 100%;
                position: relative;
                display: block;
                width: 100%;
                img {
                    max-width: 100%;
                    height: 100%;
                    @include res(object-fit,fill,(xs:cover));
                }
            }
        }
        .swiper-pagination {
            @include res(bottom,0.53rem,(xs:10px));
            .swiper-pagination-bullet {
                width: 8px;
                height: 8px;
                background-color: #b5b5b5;
                opacity: 1;
                margin: 0;
                @include res(margin-right,20px,(1 / 2));
                &:last-child {
                    margin-right: 0;
                }
            }
            .swiper-pagination-bullet-active {
                background-color: #ffffff;
            }
        }
    }
}
.index {
    .section {
        @include res(height,100%,(xs:auto));
    }
    .page1_bg {
        @include res(height,100%,(xs:auto));
    }
    .page2_bg {
        @include res(height,100%,(xs:auto));
    }
    .page3_bg {
        @include res(height,100%,(xs:auto));
    }
    .page4_bg {
        @include res(height,100%,(xs:auto));
    }
    .page5_bg {
        @include res(height,100%,(xs:auto));
    }
    .page6_bg {
        @include res(height,100%,(xs:auto));
    }
    .section1 {
        @include res(margin-bottom,0,(xs:30px));
        position: relative;
        width: 100%;
        height: 100%;
        .container {
            display: flex;
            justify-content: space-between;
            @include res(flex-wrap,nowrap,(xs:wrap));
            @include res(padding-top,1.5rem,(mmd:2rem,xs:30px));
            .left {
                position: relative;
                z-index: 1;
                @include res(width,561px,(lg:40%,xs:100%));
                font-size: 14px;
                color: #202020;
                .text {
                    @include res(margin-top,80px,(1 / 4));
                    @include res(width,520px,(lg:100%));
                    line-height: 30px;
                    text-align: justify;
                }
                a {
                    display: block;
                    width: fit-content;
                    @include res(margin-top,35px,(20 / 35));
                    img {
                        @include res(margin-left,0.12rem);
                    }
                    &:hover {
                        .scroll-cont {
                            div {
                                transform: translateY(-100%);
                                color: #000;
                                img {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    .scroll-cont {
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        height: 17px;
                        div {
                            display: flex;
                            align-items: center;
                            transition: all .25s;
                            img {
                                opacity: .6;
                            }
                        }
                    }
                }
            }
            .right {
                @include res(width,750px,(lg:55%,xs:100%));
                @include res(margin-top,0,(xs:30px));
                ul {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-align: center;
                    @include res(flex-wrap,nowrap,(xs:wrap));
                    li {
                        @include res(width,fit-content,(xs:calc(100% / 3)));
                        @include res(margin-bottom,0,(xs:15px));
                        img {
                            margin: auto;
                            @include res(margin-bottom,15px,(2 / 3));
                        }
                        .mun_top {
                            font-family: OPPOSans-M;
                            color: #a50303;
                            display: flex;
                            align-items: baseline;
                            justify-content: center;
                            h2 {
                                @include res(font-size,46px,(30 / 46));
                                @include res(letter-spacing,-2px,(1 / 2));
                            }
                            span {
                                font-size: 14px;
                                letter-spacing: -1px;
                            }
                        }
                    }
                }
                .video {
                    @include res(margin-top,35px);
                    @include res(height,360px,(xs:240px));
                    width: 100%;
                    position: relative;
                    video {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .play {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        cursor: pointer;
                        img {
                            margin: auto;
                        }
                    }
                }
                .tips {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    span {
                        display: block;
                        @include res(width,50px);
                        height: 1px;
                        background-color: #d2d2d2;
                        margin-left: 12px;   
                    }
                }
            }
        }
        .bg1 {
            @include res(display,block,(md:none));
            position: absolute;
            @include res(left,7px);
            @include res(bottom,37px);
            z-index: 0;
        }
        .bg2 {
            @include res(display,block,(md:none));
            position: absolute;
            left: 0;
            @include res(bottom,42px);
            z-index: 0;
        }
        .group-nav {
            @include res(display,block,(md:none));
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 999;
            width: 100%;
            li {
                @include res(font-size,18px,(16 / 18));
                letter-spacing: 1px;
                color: #202020;
                position: absolute;
                &:nth-child(1) {
                    @include res(bottom,90px);
                    @include res(left,217px);
                }
                &:nth-child(2) {
                    @include res(bottom,52px);
                    @include res(left,424px);
                }
                &:nth-child(3) {
                    @include res(bottom,125px);
                    @include res(left,615px);
                }
                &:nth-child(4) {
                    @include res(bottom,164px);
                    @include res(left,832px);
                }
                a {
                    transition: all .3s;
                    span {
                        display: block;
                        width: 8px;
                        height: 8px;
                        background-color: #c9a063;
                        border-radius: 50%;
                        margin: auto;
                        @include res(margin-top,20px);
                        border: 4px solid transparent;
                        transition: all .3s;
                    }
                }
                &:hover {
                    a {
                        color: #c9a063;
                        span {
                            width: 14px;
                            height: 14px;
                            background-color: #fff;
                            border: 2px solid #c9a063;
                        }
                    }
                }
            }
        }
    }
    .section2 {
        position: relative;
        height: 100%;
        .container {
            @include res(position,absolute,(xs:relative));
            left: 0;
            right: 0;
            margin: auto;
            @include res(top,164px,(xs:0));
            z-index: 10;
            @include res(margin-top,0,(xs:30px));
            @include res(margin-bottom,0,(xs:30px));
            .title {
                h2 {
                    @include res(color,#fff,(xs:#c9a063));
                    @include res(opacity,.5,(xs:.15));
                }
                h3 {
                    @include res(color,#fff,(xs:#3c4242));
                }
            }
        }
        ul {
            height: 100%;
            display: flex;
            @include res(flex-wrap,nowrap,(xs:wrap));
            li {
                position: relative;
                @include res(width,calc(100% / 3),(xs:100%));
                @include res(height,100%,(xs:400px));
                transition: all .5s;
                @include res(margin-bottom,0,(xs:20px));
                .bg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    max-width: 100%;
                }
                &.active {
                    @include res(width,66.5%,(xs:100%));
                    .text {
                        h2 {
                            @include res(font-size,36px,(lg:26px));
                        }
                        p {
                            display: block;
                        }
                        .more {
                            display: flex;
                        }
                    }
                }
                &.active1 {
                    @include res(width,16.75%,(xs:100%));
                }
                .text {
                    width: fit-content;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    @include res(top,400px);
                    z-index: 2;
                    .pic {
                        @include res(width,140px,(xs:100px));
                        @include res(height,140px,(xs:100px));
                        border-radius: 50%;
                        border: solid 1px #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: auto;
                        @include res(margin-bottom,30px,(1 / 2));
                    }
                    h2 {
                        font-family: OPPOSans-M;
                        @include res(font-size,22px,(xs:26px));
                        letter-spacing: 1px;
                        color: #fff;
                        text-align: center;
                    }
                    p {
                        @include res(display,none,(xs:block));
                        @include res(width,712px,(lg:600px,xs:100%));
                        @include res(font-size,16px,(14 / 16));
                        letter-spacing: 1px;
                        color: #ffffff;
                        @include res(line-height,30px,(5 / 6));
                        text-align: center;
                        @include res(margin-top,15px,(2 / 3));
                        @include res(margin-bottom,17px,(2 / 3));
                    }
                    .more {
                        @include res(display,none,(xs:flex));
                        @include res(width,47px);
                        @include res(height,47px);
                        border-radius: 50%;
                        border: solid 1px #ffffff;
                        align-items: center;
                        justify-content: center;
                        margin: auto;
                    }
                }
                .model {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.25);
                    top: 0;
                    left: 0;
                    z-index: 1;
                }
            }
        }
    }
    .section3 {
        width: 100%;
        height: 100%;
        background: url(./images/core-bg.png)no-repeat;
        @include res(padding-bottom,0,(sm:30px));
        background-size: cover;
        .container {
            position: relative;
            height: 100%;
            .title {
                @include res(padding-top,150px);
            }
            ul {
                @include res(width,100%,(mmd:1000px,md:100%));
                @include res(margin-left,0,(mmd:auto));
                @include res(margin-right,0,(mmd:auto));
                @include res(margin-top,0,(xs:20px));
                @include res(height,500px,(lg:400px,mmd:auto));
                position: relative;
                @include res(display,block,(mmd:flex));
                align-items: stretch;
                justify-content: space-between;
                @include res(flex-wrap,nowrap,(md:wrap));
                li {
                    @include res(width,340px,(lg:240px,mmd:200px,md:175px));
                    @include res(height,340px,(lg:240px,mmd:200px,md:175px));
                    background: url(./images/core-yy.png)no-repeat;
                    background-size: cover;
                    border-radius: 50%;
                    @include res(position,absolute,(mmd:relative,md:static));
                    transition: all .3s;
                    @include res(margin-bottom,0,(sm:15px));
                    background-size: cover;
                    .core-icon {
                        width: fit-content;
                        margin: auto;
                        position: relative;
                        z-index: 10;
                        @include res(padding-top,86px,(lg:0.86rem,mmd:30px));
                        .img2 {
                            display: none;
                        }
                    }
                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: relative;
                        .bg {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            z-index: -1;
                            transition: all .3s;
                            overflow: hidden;
                            border-radius: 50%;
                            img {
                                max-width: 100%;
                                width: 100%;
                                height: 100%;
                            }
                            
                        }
                        .model {
                            position: absolute;
                            opacity: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            top: 0;
                            left: 0;
                            background: rgba(0,0,0,.3);
                            z-index: -1;
                            transition: all .3s;
                            overflow: hidden;
                        }
                    }
                    p {
                        transition: all .3s;
                        position: relative;
                        z-index: 10;
                        text-align: center;
                        margin: auto;
                        font-family: OPPOSans-H;
                        @include res(font-size,20px,(lg:0.2rem,mmd:14px));
                        color: #3c4242;
                        line-height: 24px;
                        @include res(margin-top,28px,(lg:0.28rem,mmd:10px));
                    }
                    &:nth-child(1) {
                        @include res(bottom,0,(mmd:-75px));
                        @include res(left,0,(lg:100px,mmd:75px));
                    }
                    &:nth-child(2) {
                        @include res(top,50px,(mmd:35px));
                        @include res(left,277px,(lg:278px,mmd:35px));
                    }
                    &:nth-child(3) {
                        @include res(top,0);
                        @include res(left,565px,(lg:0));
                        @include res(right,auto,(lg:0));
                        @include res(margin-left,auto,(md:0));
                        @include res(margin-right,auto,(md:0));
                    }
                    &:nth-child(4) {
                        @include res(top,50px,(mmd:35px));
                        @include res(right,277px,(lg:278px,mmd:35px));
                    }
                    &:nth-child(5) {
                        @include res(bottom,0,(mmd:-75px));
                        @include res(right,0,(lg:100px,mmd:75px));
                    }
                    &:hover,&.active {
                        a {
                            .core-icon {
                                .img1 {
                                    display: none;
                                }
                                .img2 {
                                    display: block;
                                }
                            }
                            p {
                                color: #fff;
                            }
                            .bg {
                                opacity: 1;
                                z-index: 0;
                            }
                            .model {
                                opacity: 1;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
            .core-text {
                .text {
                    display: none;
                    width: fit-content;
                    @include res(position,absolute,(lg:relative));
                    left: 0;
                    right: 0;
                    margin: auto;
                    @include res(bottom,60px,(lg:auto));
                    @include res(top,auto,(lg:-60px,mmd:60px,md:0));
                    text-align: center;
                    h2 {
                        font-family: OPPOSans-H;
                        @include res(font-size,24px,(20 / 24));
                        color: #c9a063;
                        margin-bottom: 10px;
                    }
                    p {
                        @include res(font-size,16px,(14 / 16));
                        @include res(line-height,30px,(25 / 30));
                        color: #3c4242;
                    }
                    &.active {
                        display: block;
                    }
                }
            }    
        }
    }
    .section4 {
        @include res(margin-top,145px,(30 / 145));
        ul {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            @include res(flex-wrap,nowrap,(xs:wrap));
            @include res(margin-top,55px,(20 / 55));
            li {
                @include res(width,316px,(lg:24%,xs:100%));
                @include res(margin-bottom,0,(xs:20px));
                .pic {
                    overflow: hidden;
                    img {
                        transition: all .3s;
                        aspect-ratio: 472 / 279;
                        max-width: 100%;
                    }
                }
                .text {
                    position: relative;
                    @include res(height,250px,(lg:200px,xs:auto));
                    background-color: #f7f7f7;
                    color: #3c4242;
                    transition: all .3s;
                    @include res(padding-left,20px,(1 / 2));
                    @include res(padding-right,20px,(1 / 2));
                    @include res(padding-bottom,0,(xs:20px));
                    h2 {
                        font-family: OPPOSans-H;
                        font-size: 16px;
                        line-height: 30px;
                        @include res(padding-top,17px,(10 / 17));
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                   
                    }
                    p {
                        font-size: 14px;
                        line-height: 24px;
                        margin-top: 15px;
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        @include res(-webkit-line-clamp,3,(lg:2));
                    }
                }
                .time {
                    @include res(font-size,18px,(16 / 18));
                    @include res(position,absolute,(xs:static));
                    @include res(bottom,23px,(xs:0));
                }
                &:hover {
                    .pic {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .text {
                        color: #fff;
                        background-color: #c9a063;
                    }
                }
            }
        }
    }
    .section5 {
        position: relative;
        width: 100%;
        height: 100%;
        .bg {
            @include res(display,flex,(xs:block));
            height: 100%;
            div {
                @include res(width,50%,(xs:100%));
            }
            .left {
                @include res(height,auto,(xs:400px));
            }
            .right {
                @include res(height,auto,(xs:450px));
            }
            img {
                max-width: 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .container {
            @include res(position,absolute);
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: space-between;
            @include res(flex-wrap,nowrap,(xs:wrap));
            z-index: 10;
            .left {
                @include res(width,500px,(mmd:45%,xs:100%));
                @include res(height,auto,(xs:400px));
                box-sizing: border-box;
                @include res(padding-top,0,(xs:20px));
                p {
                    @include res(font-size,16px,(14 / 16));
                    @include res(line-height,30px,(5 / 6));
                    color: #3c4242;
                    @include res(margin-top,30px);
                }
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    @include res(width,143px);
                    @include res(height,49px);
                    background-color: #c9a063;
                    box-sizing: border-box;
                    @include res(margin-top,35px,(20 / 35));
                    font-size: 14px;
                    color: #ffffff;
                    img {
                        @include res(margin-left,0.15rem);
                    }
                    &:hover {
                        .scroll-cont {
                            div {
                                transform: translateY(-100%);
                                color: #fff;
                                img {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    .scroll-cont {
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        height: 17px;
                        div {
                            display: flex;
                            align-items: center;
                            transition: all .25s;
                            img {
                                opacity: .6;
                            }
                        }
                    }
                }
            }
            .right {
                @include res(width,508px,(mmd:45%,xs:100%));
                @include res(margin-top,0,(xs:20px));
                @include res(height,auto,(xs:450px));
                .pic {
                    width: fit-content;
                    margin: auto;
                }
                h2 {
                    font-family: OPPOSans-H;
                    @include res(font-size,36px,(lg:0.36rem,xs:24px));
                    letter-spacing: -1px;
                    color: #3c4242;
                    text-align: center;
                    margin-top: 10px;
                }
                ul {
                    width: 100%;
                    margin-top: 10px;
                    li {
                        font-size: 14px;
                        div {
                            @include res(padding-left,20px,(1 / 2));
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            width: 100%;
                            @include res(height,50px);
                            background: rgba(255,255,255,.6);
                            margin-bottom: 10px;
                            color: #3c4242;
                            line-height: 30px;
                            input {
                                border: none;
                                outline: none;
                                background: transparent;
                                flex: 1;
                                display: block;
                                width: 100%;
                            }
                        }
                        &:first-child {
                            display: flex;
                            div {
                                @include res(width,250px);
                                @include res(height,50px);
                                &:first-child {
                                    @include res(margin-right,8px);
                                }
                            }
                        }
                        &:nth-child(3) {
                            @include res(height,100px);
                            div {
                                height: 100%;
                                @include res(padding-top,20px,(1 / 2));
                                align-items: flex-start;
                            }
                            textarea {
                                resize: none;
                                border: none;
                                outline: none;
                                background: transparent;
                                width: 100%;
                                height: 100%;
                                flex: 1;
                            }
                        }
                        &:nth-child(4) {
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: 10px;
                            h3 {
                                width: 100%;
                                margin-bottom: 10px;
                            }
                            div {
                                padding: 0;
                                margin-bottom: 0;
                                background: transparent;
                            }
                            .verify-code {
                                font-size: 20px;
                                text-align: center;
                                cursor: pointer;
                                width: 200px!important;
                                height: 40px!important;
                                box-sizing: border-box;
                                margin-bottom: 0;
                                box-sizing: border-box;
                                margin-left: 10px;
                            }
                            
                            .cerify-code-panel {
                                height:100%;
                                overflow:hidden;
                                margin-bottom: 0;
                            }
                            
                            
                            
                            .verify-input-area {
                                width: 60%;
                                padding-right: 10px;
                                
                            }
                            
                            .verify-change-area {
                                line-height: 30px;
                            }
                            
                            .varify-input-code {
                                display:inline-block; 
                                width: 100px;
                                height: 40px;
                                border: 1px solid ;
                                margin-left: 20px;
                            }
                            
                            .verify-change-code {
                                color: #337AB7;
                                cursor: pointer;
                            }
                            
                            .verify-btn {
                                width: 200px;
                                height: 30px;
                                background-color: #337AB7;
                                color:#FFFFFF;
                                border:none;
                                margin-top: 10px;
                            }
                            
                        }
                        &:last-child {
                            margin-top: 10px;
                            display: flex;
                            justify-content: flex-end;
                            input {
                                &[type="submit"] {
                                    border: none;
                                    @include res(width,160px);
                                    @include res(height,50px);
                                    background-color: #c9a063;
                                    color: #fff;
                                    letter-spacing: 3px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    footer {
        height: 300px;
        padding-bottom: 0;
        @include res(padding-top,30px);

    }
}
@media screen and (max-height: 840px) {
    .index .section4 ul li .pic img {
        aspect-ratio:3 / 2;
    }
}
@media screen and (max-height: 800px) {
    .index .section4 {
        margin-top: 100px;
    }
    .index .section3 .container .title {
        padding-top: 100px;
    }
    .index .section2 .container {
        top: 100px;
    }
    .index .section3 .container .core-text .text {
        position: relative;
        bottom: auto;
        top: -140px;
    }
    .index .section4 ul li .text p {
        -webkit-line-clamp: 2;
    }
    .index .section4 ul li .text {
        height: 200px;
    }
}

@media screen and (max-height: 770px) {
    .index .section1 .bg2 {
        display: none;
    }
    .index .section1 .group-nav {
        display: none;
    }
}
@media screen and (max-height: 750px) {
    .index .section3 .container ul {
        top: -50px;
    }
    .index .section3 .container .core-text .text {
        top: -160px;
    }
    .index .section4 ul {
        margin-top: 20px;
    }
    .index footer {
        height: 360px;
    }
    .index .section2 ul li .text {
        top: 230px;
    }
}

@media (max-width: 1600px) and (max-height: 800px) {
    .index .section3 .container ul {
        top: -50px;
    }
    .index .section3 .container .core-text .text {
        top: -80px;
    }
}
@media (max-width: 1600px) and (max-height: 680px) {
    .index .section4 ul li .pic {
        
    }
}
@media (max-width: 1366px) and (max-height: 800px) {
    .index .section3 .container ul {
        top: 0px;
    }
    .index .section3 .container .core-text .text {
        top: 60px;
    }
}
@media screen and (max-width: 1024px) {
    .index .section3 .container .core-text .text {
        top: 0;
        margin-top: 30px;
    }
}
@media screen and (max-width: 1024px) and (max-height: 800px) {
    .index .section4 {
        margin-top: 30px;
    }
    .index .section3 .container .title {
        padding-top: 30px;
    }
    .index footer {
        height: auto;
    }
    .index .section2 .container {
        top: 0;
    }
    .index .section2 ul li .text {
        top: 30px;
    }
}
